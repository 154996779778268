import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
import 'element-plus/dist/index.css'
import 'swiper/css'
import Api from '@/api/Api.js'
import regCpn from '@/components/global/regCpn.js'
import '@/api/Index.js'
import '@/assets/css/base.css'
import '@/assets/scss/init.scss'

const app = createApp(App)
app.config.globalProperties.Api = Api
app.config.globalProperties.$store = store
app.use(store).use(router).use(ElementPlus, { locale })
app.use(regCpn)
//引入
//挂载
app.mount('#app')

if (process.env.NODE_ENV === 'production') {
  //百度统计
  var _hmt = _hmt || []
  window._hmt = _hmt // 修改为window 全局变量
  ;(function () {
    var hm = document.createElement('script')
    hm.src = 'https://hm.baidu.com/hm.js?84ebda1a5b8f6c6f9ac2309e85210a6d'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(hm, s)
  })()
}
