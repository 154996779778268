<template>
  <el-menu
    class="cpn-nav"
    mode="horizontal"
    text-color="#FFF"
    background-color="transparent"
  >
    <SubMenu :navs="navs"></SubMenu>
  </el-menu>
</template>
<script>
import SubMenu from './vnav/SubMenu.vue'
export default {
  log: '',
  components: { SubMenu },
  props: {},
  data() {
    return {
      activeIndex: 0,
      navs: [
        { name: '首页', path: '/' },
        { name: '中国天然氧吧', path: '/cnoz/' },
        { name: '中国气候宜居城市(县)', path: '/cclc/' },
        { name: '避暑旅游目的地', path: '/ccsd/' },
        { name: '气候生态品牌效益评估', path: '/newsXYPGList' },
        { name: '关于我们', path: '/aboutUs' }
      ],
      menuCurrent: '/'
    }
  },
  watch: {
    cropList: {
      handler() {
        this.initCropData()
      }
    }
  },
  computed: {
    GStroe() {
      return this.$store.state.global
    },
    cropList() {
      return this.GStroe.cropList
    }
  },
  created() {
    this.initCropData()
  },
  mounted() {},
  methods: {
    initCropData() {
      let grainList = [],
        featureList = []
      if (this.cropList?.length) {
        for (let item of this.cropList) {
          let obj = {
            name: item.cropName,
            path: item.cropCode
          }
          if (item.cropCategoryID == 100) {
            grainList.push(obj)
          } else if (item.cropCategoryID == 200) {
            featureList.push(obj)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-nav {
  padding-left: 10px;
  font-size: 24px;
}
</style>
