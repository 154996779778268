<template>
  <div class="body-box">
    <header class="header-box" v-if="$route.name && $route.name !== '404'">
      <VHead></VHead>
    </header>
    <main class="main-box">
      <router-view v-slot="{ Component }" v-if="isShow">
        <transition :name="transitionName">
          <component class="slide-view" :is="Component" />
        </transition>
      </router-view>
    </main>
    <footer class="footer-box" v-if="$route.name && $route.name !== '404'">
      <VFoot></VFoot>
    </footer>
  </div>
</template>
<script>
import VHead from './index/VHead.vue'
import VFoot from './index/VFoot.vue'
import { DefAjax } from '@/service/ajax.js'
export default {
  components: {
    VHead,
    VFoot
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isShow: false,
      transitionName: ''
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from) {
        if (
          from &&
          to.meta.animate &&
          from.meta &&
          from.meta.footerIndex >= 0
        ) {
          if (to.meta.footerIndex > from.meta.footerIndex) {
            this.transitionName = 'slide-right'
          } else {
            this.transitionName = 'slide-left'
          }
        }
      }
    }
  },
  computed: {},
  created() {
    this.verInfo()
  },
  mounted() {
    this.isMobileApp()
    this.initEvent()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.isMobileApp)
  },
  methods: {
    initEvent() {
      window.addEventListener('resize', this.isMobileApp)
    },
    isMobileApp() {
      if (
        (window.innerWidth <= 768 || this.Api.isMobile() == true) &&
        process.env.NODE_ENV === 'production'
      ) {
        window.location.href = this.Api.host + 'wxgp/'
      }
    },
    reload() {
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
    },
    verInfo() {
      DefAjax({
        url: 'api/news/type'
      })
        .then(() => {
          this.$store.state.info.infoConnect = true
          this.isShow = true
        })
        .catch(() => {
          this.$store.state.info.infoConnect = false
          this.isShow = true
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.m-nav {
  display: flex;
  li {
    height: 20px;
    font-size: 14px;
  }
}
.body-box {
  background: #fff url('@/assets/img/bg/white-bg.jpg') center repeat;
}
.header-box {
  position: relative;
  z-index: 10;
}
.main-box {
  min-height: 300px;
  z-index: 1;
}
.left-box {
  position: absolute;
  left: 0;
  top: 135px;
  bottom: 0;
  width: 80px;
}
.footer-box {
  z-index: 2;
}
.slide-view {
  width: 100%;
  height: 100%;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-to {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-from {
  position: absolute;
  transform: translate3d(100%, 0, 0);
}

.slide-right-leave-to {
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-from {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

/**/
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-to {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-from {
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}

.slide-left-leave-to {
  position: absolute;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-from {
  position: absolute;
  transform: translate3d(0, 0, 0);
}
</style>
